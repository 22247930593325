export default class AppContext {
    /** @var AppContext _appContext */
    static _appContext = null;
    /** @return AppContext */
    static getInstance() {
        if (!this._appContext) {
            this._appContext = new AppContext();
        }
        return this._appContext;
    }

    value;

    setValue(value) {
        this.value = value;
    }
}
