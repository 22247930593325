import BoxWrapper from "./BoxWrapper";
import DesignAssignments from "./DesignAssignments";
import {useEffect, useRef, useState} from "react";
import {Tooltip} from "react-tooltip";

export default function BoxDesignAssignments({expanded, all, onExpandToggle, essentials}) {

    const [assignments, setAssignments] = useState([]);
    const [assignmentsCount, setAssignmentsCount] = useState(null);
    const boxWrapper = useRef(null);

    const fetchUrl = all ? '/api/designs/current' : '/api/designs/my/current';

    useEffect(() => {
        fetch(fetchUrl, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setAssignments(json.assignments);
                setAssignmentsCount(json.total);
                updateTotal(json.total);
            });
    }, []);

    function updateTotal(count) {
        if (boxWrapper && boxWrapper.current) boxWrapper.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
    }


    return (<BoxWrapper
        title={all ? "Projekty" : "Moje Projekty"}
        icon={all ? "designs" : "design" }
        cta={all ? "Przejdź do Projektów" : "Przejdź do Moich Projektów"}
        action={all ? "/design" : "/design/moje" }
        className={"all-design"+(expanded ? " expanded":'')}
        collapseOnMobile={true}
        ref={boxWrapper}
        onExpandToggle={onExpandToggle}
        loaderCondition={ () => assignmentsCount === null}>
            <div className="design_assignments_list assignments_list">
                <DesignAssignments assignments={assignments} total={assignmentsCount} essentials={essentials} setAssignments={setAssignments}></DesignAssignments>
            </div>
            <Tooltip id="common-tooltip" />
        </BoxWrapper>
    )
}
