import React, {useContext} from "react";
import {SharedContext} from "../index";
import OfferRow from "./OfferRow";
import OfferStatusPopup from "./OferStatusPopup";
import AnimateRow from "./AnimateRow";

export default function Animates({animates: animates, total}) {

    let wrapper = null;
    let ctx = useContext(SharedContext);

    function openAnimate(animate) {
        window.location.href = animate.previewUrl
    }

    return (
        <ul className="assignments-wrapper" ref={elem => wrapper = elem}>
            { animates ? animates.map((animate, i) => AnimateRow({
                animate,
                open: openAnimate
            })) : '' }
        </ul>
    )
}
