import useAssignmentsBrowserService from "./generic/useAssignmentsBrowserService";

export default function usePublishingAssignmentsBrowserService(range, appContext) {
    return useAssignmentsBrowserService({
        range,
        defaultFilters: appContext.value.statusFilter,
        apiEndpoint: '/api/assignments/search',
        assignmentType: 'publishing'
    });
}
