import useAssignmentsBrowserService from "./generic/useAssignmentsBrowserService";

export default function useDesignAssignmentsBrowserService(range, appContext) {
    return useAssignmentsBrowserService({
        range,
        defaultFilters: appContext.value.statusFilter,
        apiEndpoint: '/api/designs/search',
        assignmentType: 'design'
    });
}
