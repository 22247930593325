import InputBoxWithButton from "./InputBoxWithButton";
import StatusCheckbox from "./StatusCheckbox";
import {AppContext} from "../index";
import "./AssignmentsBrowserHeader.scss";
import {useEffect, useRef, useState} from "react";
import Select from "react-select/base";

// searchQueryInput={browserService.searchQueryInput}
// handleSearchChange={val => browserService.handleSearchChange(val)}
// statusFilter={browserService.statusFilter}
// toggleStatusFilter={(slug,status) => browserService.toggleStatusFilter(slug,status)}

export default function AssignmentsBrowserHeader({searchQueryInput, handleSearchChange, statusFilter, toggleStatusFilter, statuses, company, toggleCompany, moreFilters, actions}) {
    const appContext = AppContext.getInstance();
    const inputRef = useRef(searchQueryInput);
    const selectRef = useRef(null);
    const companies = [
        { value: "", label: "- wszystkie -"}
    ].concat(appContext.value.companies.map(c =>
        { return {value: c.id, label: c.name} }
    ));
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = searchQueryInput;
        // if (window.jQuery) {
            // let $companyFilter = window.jQuery('#company_filter');
            // $companyFilter.select2();
            // $companyFilter.val(company).trigger('change');
            // $companyFilter.on('change', e => toggleCompany(e.currentTarget.value))
            // $companyFilter.select2.value(company)
        // }
        // ref.current.value = company;
    }, [])

    useEffect( () => {
        document.addEventListener('keyup', clearSearchOnEscape);
        return () => {
            document.removeEventListener('keyup', clearSearchOnEscape);
        }
    }, []);

    function clearSearchOnEscape(e) {
        if (e.key === "Escape") {
            clearInput();
        }
    }


    function clearInput() {
        inputRef.current.value = '';
        handleSearchChange('');
        inputRef.current.focus();
    }

    return (
        <div className="assignmentsBrowserHeader">
            <div className="item wide">
                <div>Filtrowanie</div>
                <InputBoxWithButton placeholder="Nazwisko, klient, @id, nazwa zlecenia" buttonLabel="✕" buttonAction={clearInput} ref={inputRef} onChange={(v) => handleSearchChange(v) } />
                { moreFilters ?? '' }
            </div>

            <div className="item">
                <div>Status</div>
                <div className="status_checkboxes">
                    { statuses.map(s =>
                        <StatusCheckbox
                            status={s}
                            checked={statusFilter[s.slug]}
                            tooltip={s.name}
                            onChange={newStatus => toggleStatusFilter(s.slug, newStatus)}
                        />
                    )}
                </div>
            </div>

            <div className="item">
                <div>Spółka</div>
                <Select
                    ref={selectRef}
                    options={companies}
                    value={company || companies[0]}
                    onChange={(newValue) => {toggleCompany(newValue)}}
                    onMenuOpen={_=>{setIsMenuOpen(true)}}
                    onMenuClose={_=>{setIsMenuOpen(false)}}
                    onInputChange={_=>{}}
                    isSearchable={false}
                    menuIsOpen={isMenuOpen}
                    classNamePrefix="react-select"
                ></Select>
            </div>
            { actions ?? '' }
        </div>
    )
}
