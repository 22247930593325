import React from "react";
import "./ConfigToggleBar.scss";
import {motion, useDragControls} from 'framer-motion';
import {useMeasurePosition} from "../hooks/use-measure-position";

export default function ConfigToggleBar({title, checked, onToggle, updateOrder, updatePosition, ind, constraintsRef}) {
    const [isdragged, setIsDragged] = React.useState(false);
    const itemRef = useMeasurePosition(pos => updatePosition(ind, pos));
    const dragControls = useDragControls();

    function startDrag(event) {
        dragControls.start(event, { snapToCursor: true })
    }

    let motionAttributes = {
        drag: "y",
        dragControls: dragControls,
        // dragListener: false,
        dragConstraints: constraintsRef,
        style: {
            zIndex: isdragged ? 3 : 1
        },
        dragElastic: 0,
        layout: true,
        ref: itemRef,
        onDragStart: () => setIsDragged(true),
        onDragEnd: () => setIsDragged(false),
        animate: {
            scale: isdragged ? 1.05 : 1
        },
        onViewportBoxUpdate: (_, delta) => {
            isdragged && updateOrder(ind, delta.y.translate)
        }
    }

    return <motion.div {...motionAttributes} className="configToggleBar">
        <div className="checkbox" onClick={onToggle}>
            <img src={`/ui/icons-black/round-checkmark-${checked ? 'checked' : 'unchecked'}.svg`} alt=""/>
        </div>
        <div className="title">{title}</div>
        <div className="handle" onPointerDown={startDrag}>
            <img src="/ui/icons-black/handle.svg" alt=""/>
        </div>
    </motion.div>
}
