import "./InputBoxWithButton.scss";
import {forwardRef} from "react";
let InputBoxWithButton = forwardRef(({placeholder, buttonLabel, buttonAction, value, onChange}, forwardedRef) => (
            <div className="input_box_with_button">
                <div className="input_box_with_button_wrapper">
                    <input placeholder={placeholder} ref={forwardedRef} type="text" value={value} onChange={(e) => onChange(e.target.value) }/>
                    <div className="button" onClick={buttonAction}>{buttonLabel}</div>
                </div>
            </div>
        ));
export default InputBoxWithButton;
