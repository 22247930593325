// import PropTypes from "prop-types";

import {forwardRef, useEffect, useRef, useState} from "react";
import "./BoxWrapper.scss";

const BoxWrapper = forwardRef(({ children, title, icon, badge, cta, action, className, collapseOnMobile, onExpandToggle: handleToggleExpand, expanded: initialExpandStata, loaderCondition }, forwardedRef) => {

    const [liveBadge, setLiveBadge] = useState(badge);
    const [expanded, setExpanded] = useState(initialExpandStata ?? false);
    const fallbackRef = useRef(null);
    const boxRef = forwardedRef || fallbackRef;
    let box;
    const actionButtonElementRef = useRef(null);

    useEffect(() => {
        box = boxRef.current;
        box && box.addEventListener("setBadge", handleSetBadgeEvent);
        return () => {
            box && box.removeEventListener("setBadge", handleSetBadgeEvent);
        }
    }, [])

    /**
     * Change box badge
     * to dispatch event use: element.dispatchEvent('setBadge', { detail: 'new badge' })
     * @param e
     */
    function handleSetBadgeEvent(e) {
        e.stopPropagation();
        setLiveBadge(e.detail);
    }

    function isHidden(el) {
        return (el.offsetParent === null)
    }

    function mobileActionHandler(action) {
        if (isHidden(actionButtonElementRef.current)) {
            window.location.href = action;
        }
    }

    function getClassName() {
        let classNames = ["box-wrapper", className];
        if (collapseOnMobile) {
            classNames.push('mobile-collapsable');
            if (expanded) classNames.push('expanded');
        }
        return classNames.join(' ');
    }

    function toggleExpanded(e) {
        setExpanded(!expanded);
        handleToggleExpand(!expanded);
        e.stopPropagation();
    }

    return (
        <div className={getClassName()} ref={boxRef}>
            <div className={action ? "header has-action" : "header"} onClick={_ => action && mobileActionHandler(action) }>
                <div className="title">
                    <div className="icon">
                        <img src={`/ui/icons/${icon}.svg`} alt="" />
                    </div>
                    <div className="label">
                        { title }
                    </div>
                    <div className="badge">{ liveBadge }</div>
                </div>
                { cta ? <div className="actions">
                    <a className="button" href={action} ref={actionButtonElementRef}>
                        <div className="label">{cta}</div>
                        <div className="icon">
                            <img src="/ui/icons/right-arrow.svg" alt=""/>
                        </div>
                    </a>
                </div> : ''}
                { collapseOnMobile ? <div className="collapseTrigger" onClick={toggleExpanded}>
                    <div className="icon">
                        <img src="/ui/icons/expand.svg" alt=""/>
                    </div>
                </div> : ''}
            </div>
            <div className="content">
                { children }
                { typeof loaderCondition === 'function' && loaderCondition()
                    ? <div className="assignmentLoaderIndicator"><img src="/ui/bouncing-circles.svg" alt=""/></div>
                    : ''
                }
            </div>
        </div>
    );
});

export default BoxWrapper;
