import {useEffect, useRef, useState} from "react";

export default function useAllNotificationsService() {
    const NUMBER_OF_NOTIFICATIONS = 40;
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(null);
    const [loading, setLoading] = useState(false);
    let eventTarget = useRef(null);
    const [empty, setEmpty] = useState(false);

    useEffect( () => {
        let intervalId;
        loadNotifications();
        intervalId = setInterval(() => setNotifications(n => {
            loadNotifications(n.length);
            return n;
        }), 30000);
        document.addEventListener('notificationsUpdate', loadNotificationsFromEvent);
        document.addEventListener('closedNotifications', removeNotificationsFromEvent);
        return () => {
            clearInterval(intervalId);
            document.removeEventListener('notificationsUpdate', loadNotificationsFromEvent);
            document.removeEventListener('closedNotifications', removeNotificationsFromEvent);

        }
    }, []);

    useEffect(updateTotal, [notificationsCount]);
    useEffect(loadMore, [notifications]);

    function loadNotifications(atLeast) {
        if (loading) return;
        setLoading(true);
        let url = '/api/notifications';
        if (atLeast > NUMBER_OF_NOTIFICATIONS) {
            url+=`?atLeast=${atLeast}`
        }
        fetch(url, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setNotifications(json.notifications);
                setNotificationsCount(json.total);
                setLoading(false);
            });
    }

    function loadMoreNotifications() {
        if (!notifications.length) return loadNotifications();
        if (loading) return;
        setLoading(true);
        const lastId = notifications[notifications.length-1].id;
        fetch(`/api/notifications?olderThan=${lastId}`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setNotifications( notifications => {
                    let moreNotifications = json.notifications.filter(newNotification => !notifications.find(existingNotification => existingNotification.id === newNotification.id));
                    return notifications.concat(moreNotifications);
                });
                setNotificationsCount(json.total);
                setLoading(false);
                // TODO: BUG when loading more - can't remove first
            });
    }

    function loadNotificationsFromEvent(e) {
        if (e.target !== eventTarget.current) {
            const count = e.detail?.count;
            if (count !== undefined) setNotificationsCount(count);
            const eventNotifications = e.detail?.notifications;
            if (eventNotifications !== undefined) setNotifications(eventNotifications);
        }
    }

    function removeNotificationsFromEvent(e) {
        if (e.target !== eventTarget.current) {
            const ids = e.detail?.ids?.map(id => parseInt(id, 10));
            if (Array.isArray(ids)) {
                let removedCount = 0;
                setNotifications(notifications => notifications.filter(n => {
                    const shouldRemove = ids.includes(parseInt(n.id));
                    if (shouldRemove) removedCount ++;
                    return !shouldRemove
                } ));
                setNotificationsCount(count => count - removedCount);
            }
        }
    }

    function loadMore() {
        setTimeout(() => {
            if (notifications.length < NUMBER_OF_NOTIFICATIONS && notifications.length < notificationsCount) {
                loadMoreNotifications();
            }
        }, 500);
    }

    function updateTotal() {
        const count = notificationsCount;
        if (eventTarget && count !== null) {
            eventTarget.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
            eventTarget.current.dispatchEvent(new CustomEvent('notificationsUpdate', {detail: {count, notifications}, bubbles: true}));
        }

        if (count !==null) {
            document.title = document.title.replace(/<\s+\d+\s+>/, '< ' + count + ' >');
        }

        if (count === 0) {
            setEmpty(true);
        }
        else {
            setEmpty(false);
        }
    }
    function notifyAboutClosed(ids) {
        eventTarget.current.dispatchEvent(new CustomEvent('closedNotifications', {detail: {ids}, bubbles: true}));
    }

    function closeNotification(id, event) {
        // php echo url_for('users/kill_notification?id=' . $notification->getId())
        const modifier = event ? (event.altKey ? '?other-related=true' : (event.shiftKey ? '?related=true' : '')) : '';
        setNotifications( notifications => notifications.map( notification => notification.id === id ? Object.assign({}, notification, {is_removing: true}) : notification ));
        fetch(`/api/notifications/${id}/close${modifier}`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                if (json.hasOwnProperty('status') && json.status === 'ok' && !json.hasOwnProperty('notifications')) {
                    if (modifier) {
                        notifyAboutClosed(json.deleted_ids);
                        loadNotifications(notifications.length);
                    }
                    else {
                        notifyAboutClosed([id]);
                        setNotifications(notifications => notifications.filter(notification => notification.id !== id));
                    }
                    if (json.hasOwnProperty('total')) {
                        setNotificationsCount(json.total);
                    }
                } else if (json.status === 'error' && json.code === 'not found') {
                    loadNotifications(notifications.length);
                } else {
                    setNotifications( notifications => notifications.map( notification => notification.id === id ? Object.assign({}, notification, {is_removing: false}) : notification ));
                }
            });
    }

    async function deleteAll(e) {
        e.preventDefault();
        if (window.confirm('Na pewno usunąć wszystkie powiadomienia?')) {
            const url = `/users/clearNotifications`;
            const response = await fetch(url);
            // const json = await response.json();
            if (response.status === 200) {
                console.log('Notifications cleared.');
                // TODO: Whats the purpose of hiding and showing notifications
                // setHidden(true);
                // setTimeout(()=>setHidden(false));
            }
        }
        return false;
    }

    return {loading, empty, notifications, eventTarget, closeNotification, deleteAll}
}
