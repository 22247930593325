import "./AssignmentsBrowser.scss";
import {AppContext} from "../index";
import Animates from "./Animates";
import useAnimatesBrowserService from "../hooks/browserServices/useAnimatesBrowserService";
import AnimatesBrowserHeader from "./AnimatesBrowserHeader";
import {useEffect, useMemo} from "react";

function AnimatesBrowser({range}) {
    const appContext = AppContext.getInstance();
    const browserService = useAnimatesBrowserService(range, appContext);
    useEffect(() => {
        browserService.cleanObsolete();
    }, [])

    return (<div className='assignmentsBrowser'>
        <AnimatesBrowserHeader
            searchQueryInput={browserService.searchQueryInput}
            handleSearchChange={val => browserService.handleSearchChange(val)}
        />
        <div className="assignmentsBrowserBody" ref={browserService.scrollableBody}>
            <Animates animates={browserService.assignments}></Animates>
            { browserService?.page?.loading ? <div className='assignmentLoaderIndicator'><img src="/ui/bouncing-circles.svg" alt=""/></div> : '' }
        </div>
    </div>)
}

export default AnimatesBrowser;
