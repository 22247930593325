import React from "react";
import "./Notifications.scss";
import "./NotificationsList.scss";
import NotificationRow from "./NotificationRow";
import useAllNotificationsService from "../hooks/useAllNotificationsService";

export default function Notifications({service, removeAllLabel}) {
    // const [hidden, setHidden] = useState(false);
    const {loading, empty, notifications, eventTarget, closeNotification, deleteAll} = service;
    return (
        <>
            <div className="notifications_list" ref={eventTarget}>
                <ul className="notifications-wrapper" >
                    { loading ? <div className="assignmentLoaderIndicator"><img src="/ui/bouncing-circles.svg" alt=""/></div> : '' }
                    { notifications.map((notification,i) => NotificationRow({notification, closeNotification, key: notification.id})) }
                </ul>
            </div>
            { empty ? <div className="empty-notifications-message">Brak powiadomień</div> : <a className="del_all_notification_btn" href="#" onClick={deleteAll}>{removeAllLabel || 'Usuń wszystkie powiadomienia'}</a>}
        </>
    )
}
