import "./StatusCheckbox.scss";
import {AppContext} from "../index";
import {useEffect, useState} from "react";

export default function StatusCheckbox({status, checked, tooltip, onChange}) {
    const color = status.color;
    const border = status.border;
    const [checkedState, setCheckedState] = useState(checked);

    function toggle(e) {
        const newCheckedState = !checkedState;
        setCheckedState(newCheckedState);
        onChange(newCheckedState);
    }

    return (
        <span className="status_checkbox" data-tooltip={tooltip} data-position="bottom center" onClick={toggle}>
            <span className={'status_checkbox_content ' + (border ? 'with_border' : '')} style={{backgroundColor: color}}>
                <span className={"checkbox_widget" + (checkedState ? ' checked' : '')}>
                    { checkedState ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Icon_material-check-box" data-name="Icon material-check-box" d="M18.722,4.5H6.278A1.777,1.777,0,0,0,4.5,6.278V18.722A1.777,1.777,0,0,0,6.278,20.5H18.722A1.777,1.777,0,0,0,20.5,18.722V6.278A1.777,1.777,0,0,0,18.722,4.5Zm-8,12.444L6.278,12.5l1.253-1.253,3.191,3.182,6.747-6.747,1.253,1.262Z" transform="translate(-4.5 -4.5)" fill="#fff"/></svg>
                    ):''}
                </span>
            </span>
        </span>
    )

}
