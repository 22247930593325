import {useEffect, useRef, useState} from "react";

export default function useAssignmentNotificationsService(assignmentType, assignmentId) {
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(null);
    const [loading, setLoading] = useState(false);
    let eventTarget = useRef(null);
    const [empty, setEmpty] = useState(false);
    const baseUrl = assignmentType === 'zlecenia_zlecenie' ? '/api/assignments' : '/api/design';

    useEffect( () => {
        loadNotifications();
        // TODO: component should fetch data periodically from api, to keep in sync in case of notification removal in other window

    }, []);

    useEffect(updateTotal, [notificationsCount]);

    useEffect(()=>{
        document.addEventListener('notificationsUpdate', notificationsCountChange);
        return () => {
            document.removeEventListener('notificationsUpdate', notificationsCountChange);
        }
    }, []);

    function loadNotifications() {
        if (loading) return;
        setLoading(true);
        let url = `${baseUrl}/${assignmentId}/notifications`;
        fetch(url, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setNotifications(json.notifications);
                setNotificationsCount(json.total);
                setLoading(false);
            });
    }

    function updateTotal() {
        const count = notificationsCount;
        if (eventTarget && count !== null) {
            eventTarget.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
        }
    }
    function notifyAboutClosed(ids) {
        eventTarget.current.dispatchEvent(new CustomEvent('closedNotifications', {detail: {ids}, bubbles: true}));
    }

    function closeNotification(id, event) {
        // php echo url_for('users/kill_notification?id=' . $notification->getId())
        const modifier = event ? (event.altKey ? '?other-related=true' : (event.shiftKey ? '?related=true' : '')) : '';
        setNotifications( notifications => notifications.map( notification => notification.id === id ? Object.assign({}, notification, {is_removing: true}) : notification ));
        fetch(`/api/notifications/${id}/close${modifier}`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => parseCloseResponse(json, id, modifier));
    }

    async function deleteAll(e) {
        e.preventDefault();
        if (window.confirm('Na pewno usunąć wszystkie powiadomienia dotyczące tego zlecenia?')) {
            fetch(`${baseUrl}/${assignmentId}/notifications/close`, {credentials: "same-origin"})
                .then(resp => resp.json())
                .then(json => parseCloseResponse(json, null, true));
        }
        return false;
    }

    function parseCloseResponse(json, id, modifier) {
        if (json.hasOwnProperty('status') && json.status === 'ok' && !json.hasOwnProperty('notifications')) {
            if (modifier) {
                notifyAboutClosed(json.deleted_ids);
                loadNotifications(notifications.length);
            }
            else {
                notifyAboutClosed([id]);
                setNotifications(notifications => notifications.filter(notification => notification.id !== id));
            }
            if (json.hasOwnProperty('total')) {
                setNotificationsCount(json.total);
            }
        } else if (json.status === 'error' && json.code === 'not found') {
            loadNotifications(notifications.length);
        } else {
            setNotifications( notifications => notifications.map( notification => notification.id === id ? Object.assign({}, notification, {is_removing: false}) : notification ));
        }
    }

    function notificationsCountChange(e) {
        if (e.detail?.count === 0) {
            setEmpty(true);
        }
        else {
            setEmpty(false);
        }
    }

    return {loading, empty, notifications, eventTarget, closeNotification, deleteAll, setNotifications}
}
