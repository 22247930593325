import InputBoxWithButton from "./InputBoxWithButton";
import "./AssignmentsBrowserHeader.scss";
import useSearchInput from "../hooks/useSearchInput";

export default function AnimatesBrowserHeader({searchQueryInput, handleSearchChange, moreFilters, actions}) {
    const [inputRef, clearInput] = useSearchInput(searchQueryInput, handleSearchChange);

    return (
        <div className="assignmentsBrowserHeader">
            <div className="item wide">
                <div>Filtrowanie</div>
                <InputBoxWithButton placeholder="Nazwisko, klient, @id, nazwa zlecenia" buttonLabel="✕" buttonAction={clearInput} ref={inputRef} onChange={(v) => handleSearchChange(v) } />
                { moreFilters ?? '' }
            </div>
            { actions ?? '' }
        </div>
    )
}
