import Notifications from "./Notifications";
import useAssignmentNotificationsService from "../hooks/useAssignmentNotificationsService";
import './AssignmentNotifications.scss';
import {createPortal} from "react-dom";
import {useEffect, useState} from "react";
import useAssignmentEventListeners from "../hooks/useAssignmentEventListeners";
export default function AssignmentNotifications({assignmentType, assignmentId}) {

    const service = useAssignmentNotificationsService(assignmentType, assignmentId);
    useAssignmentEventListeners({assignmentType, assignmentId, setNotifications: service.setNotifications});
    const {loading, notifications} = service;
    const [visibility, setVisibility] = useState(false);

    const removeAllLabel = assignmentType === 'zlecenia_zlecenie' ? 'Usuń wszystkie powiadomienia dotyczące tego zlecenia' : 'design_zlecenie' ? 'Usuń wszystkie powiadomienia dotyczące tego projektu' : 'Usuń wszystkie powiadomienia'

    function toggleVisibility(state) {
        setVisibility(v => typeof state === 'boolean' ? state : !v);
    }

    useEffect( () => {
        // Force element rerender inside container
        // Without this fix element is not painted to screen
        document.getElementById('main-popup-portal').style.display = notifications?.length ? 'block' : 'none';
    }, [notifications])

    useEffect(() => {
        document.addEventListener('keyup', escToCloseListener);
        return () => {
            document.removeEventListener('keyup', escToCloseListener);
        };
    }, [visibility]);
    function escToCloseListener(e) {
        if (e.keyCode === 27) toggleVisibility(false);
    }

    function clickOutsideToClose(e) {
        toggleVisibility(false);
    }

    return <div ref={service.eventTarget}>
        {!loading && notifications?.length ? (
            <div className="notifications-count-label" data-popup={visibility?'visible':'hidden'} onClick={toggleVisibility}><i className="fa fa-bell"></i> { notifications.length }</div>
        ) : '' }
        { createPortal(
            visibility ?
            <div className="notifications-popup">
                <div className="box-wrapper">
                    <div className="content">
                        <Notifications service={service} removeAllLabel={removeAllLabel}></Notifications>
                    </div>
                </div>
            </div> : '',
            document.getElementById('main-popup-portal')
            )
        }

    </div>
}
