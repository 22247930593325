import useAssignmentsBrowserService from "./generic/useAssignmentsBrowserService";

export default function useAnimatesBrowserService(range, appContext) {
    let browserService = useAssignmentsBrowserService({
        range,
        defaultFilters: [],
        apiEndpoint: '/api/animates/search',
        assignmentType: 'animate'
    });
    browserService.cleanObsolete = () => {
        fetch('/api/animates/cleanup', {
            credentials: "same-origin",
        })
            .then(resp => resp.json())
            .then(json => {
                console.log('Obsolete animates cleaned');
                console.log(json);
            });
    }
    return browserService;
}
