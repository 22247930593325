import BoxWrapper from "./BoxWrapper";
import {useEffect, useRef, useState} from "react";
import Offers from "./Offers";

export default function BoxOffers({expanded, onExpandToggle, essentials}) {

    const [offers, setOffers] = useState([]);
    const [offersCount, setOffersCount] = useState(null);
    const boxWrapper = useRef(null);

    useEffect( () => {
        fetch('/api/offers/my', {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setOffers(json.offers);
                setOffersCount(json.total);
                updateTotal(json.total)
            });
    }, []);

    function updateTotal(count) {
        if (boxWrapper && boxWrapper.current) boxWrapper.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
    }

    return (<BoxWrapper
        title="Moje Kosztorysy"
        icon="numbers"
        cta="Przejdź do Kosztorysów"
        action="/kosztorysy"
        className={"kosztorysy"+(expanded ? " expanded":'')}
        collapseOnMobile={true}
        ref={boxWrapper}
        onExpandToggle={onExpandToggle}
        loaderCondition={ () => offersCount === null}>
            <div className="offers_list assignments_list">
                <Offers offers={offers} total={offersCount}></Offers>
            </div>
        </BoxWrapper>
    )
}
