import InputBoxWithButton from "./InputBoxWithButton";
import StatusCheckbox from "./StatusCheckbox";
import {AppContext} from "../index";
import "./AssignmentsBrowserHeader.scss";
import "./ArchiveBrowserHeader.scss";
import {useEffect, useRef, useState} from "react";
import Select from "react-select/base";
import AssignmentsBrowserHeader from "./AssignmentsBrowserHeader";

// searchQueryInput={browserService.searchQueryInput}
// handleSearchChange={val => browserService.handleSearchChange(val)}
// statusFilter={browserService.statusFilter}
// toggleStatusFilter={(slug,status) => browserService.toggleStatusFilter(slug,status)}

export default function ArchiveBrowserHeader({searchQueryInput, handleSearchChange, statusFilter, toggleStatusFilter, statuses, company, toggleCompany, toggleType, type, quickAccess, toggleQuickAccess}) {
    const appContext = AppContext.getInstance();
    const inputRef = useRef(null);
    const typeSelectRef = useRef(null);
    const types = [
        { value: "", label: " ----- ", }
    ].concat(
        appContext.value.archiveType.map(
            type => {return { value: type.id, label: type.name }}
        )
    )
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isQAMenuOpen, setIsQAMenuOpen] = useState(false);
    const quickAccessOptions = [
        { value: "", label: "" },
        { value: "brief", label: "Brief" },
        { value: "material_od_klienta", label: "Materiał od klienta"},
        { value: "material_wewnetrzny", label: "Materiał wewnętrzny"},
        { value: "brief_wewnetrzny", label: "Brief wewnętrzny"},
        { value: "prezentacja", label: "Prezentacja dla klienta"},
        { value: "wyniki_badan", label: "Wyniki badań"},
        { value: "realizacja", label: "Realizacja"}
    ]

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = searchQueryInput;
        // if (window.jQuery) {
            // let $companyFilter = window.jQuery('#company_filter');
            // $companyFilter.select2();
            // $companyFilter.val(company).trigger('change');
            // $companyFilter.on('change', e => toggleCompany(e.currentTarget.value))
            // $companyFilter.select2.value(company)
        // }
        // ref.current.value = company;
    }, [])

    function clearInput() {
        inputRef.current.value = '';
        handleSearchChange('');
        inputRef.current.focus();
    }

    return (
        <AssignmentsBrowserHeader {...{
            searchQueryInput,
            handleSearchChange,
            statusFilter,
            toggleStatusFilter,
            statuses,
            company,
            toggleCompany,
            moreFilters: (
              <div className="item">
                  <div>Typ</div>
                  <Select
                  ref={typeSelectRef}
                  options={types}
                  value={type || types[0]}
                  onChange={(newValue) => {toggleType(newValue)}}
                  onMenuOpen={_=>{setIsMenuOpen(true)}}
                  onMenuClose={_=>{setIsMenuOpen(false)}}
                  onInputChange={_=>{}}
                  isSearchable={false}
                  menuIsOpen={isMenuOpen}
                  classNamePrefix="react-select"
                  ></Select>
              </div>
            ),
            actions: (
                <div className="quick-access-selector">
                    <label htmlFor="">Szybki dostęp</label>
                    <Select
                        // ref={typeSelectRef}
                        options={quickAccessOptions}
                        value={quickAccess || quickAccessOptions[0]}
                        onChange={(newValue) => {toggleQuickAccess(newValue)}}
                        onMenuOpen={_=>{setIsQAMenuOpen(true)}}
                        onMenuClose={_=>{setIsQAMenuOpen(false)}}
                        onInputChange={_=>{}}
                        isSearchable={false}
                        menuIsOpen={isQAMenuOpen}
                        classNamePrefix="react-select"
                    ></Select>
                </div>
            )
        }}></AssignmentsBrowserHeader>
    )
}
