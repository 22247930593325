import {useEffect} from "react";

export default function useAssignmentsEventListeners({assignmentType, setAssignments}) {
    function onClearAllNotifications(e) {
        if (e.detail?.count === 0) {
            setAssignments(assignments => assignments?.map(a => {return {...a, notifications: []}}))
        }
    }

    function onNotificationsClosed(e) {
        const ids = e.detail?.ids?.map(id => parseInt(id, 10));
        if (Array.isArray(ids)) {
            setAssignments(assignments => assignments?.map(a => { return {...a, notifications: a.notifications.filter(n => !ids.includes(n.id) ) } }));
        }
    }

    function onNotificationsUpdate(e) {
        setAssignments(assignments => {
            if (!assignments) return assignments;
            const newAssignmentsNotificationsMap = {}
            e.detail?.notifications
                .filter(notification => notification.assignmentType === assignmentType)
                .forEach( notification => {
                    let assignmentId = notification.assignmentId
                    if (assignmentId) {
                        if (!Array.isArray(newAssignmentsNotificationsMap[assignmentId])) newAssignmentsNotificationsMap[assignmentId] = [];
                        newAssignmentsNotificationsMap[assignmentId].push(notification);
                    }
                });
            if (Object.keys(newAssignmentsNotificationsMap).length) {
                return assignments.map(a => {
                    if (newAssignmentsNotificationsMap[a.id]?.length) {
                        let assignmentNotifications = a.notifications ? [...a.notifications] : [];
                        for (let newNotification of newAssignmentsNotificationsMap[a.id]) {
                            if (!assignmentNotifications.find(existingNotification => parseInt(existingNotification.id) === parseInt(newNotification.id))) {
                                assignmentNotifications.push({
                                    id: parseInt(newNotification.id),
                                    text: newNotification.text
                                });
                            }
                        }
                        return {...a, notifications: assignmentNotifications }
                    }
                    return a
                });
            }
            return assignments;
        })
    }

    useEffect( () => {
        document.addEventListener('notificationsUpdate', onClearAllNotifications);
        document.addEventListener('closedNotifications', onNotificationsClosed);
        document.addEventListener('notificationsUpdate', onNotificationsUpdate);
        return () => {
            document.removeEventListener('notificationsUpdate', onClearAllNotifications);
            document.removeEventListener('closedNotifications', onNotificationsClosed);
            document.removeEventListener('notificationsUpdate', onNotificationsUpdate);
        }
    }, []);
}
