export default function NotificationRow({notification, closeNotification}) {
    let html = {__html: notification.text?.replace(/https:\/\/zlecenia\.opusb\.pl/, 'https://new.zlecenia.opusb.pl') }
    return (
        <li data-notification-id={notification.id} key={notification.id}>
            <div className="body-and-date">
                <div className="body" dangerouslySetInnerHTML={html}></div>
                <div className="date">{ notification.date }</div>
            </div>
            { notification.is_removing ? '' : (
                <a onClick={(e) => closeNotification(notification.id, e)}
                   class="notification_closer" title="Shift = usuń wraz z pokrewnymi, Alt = usuń tylko pokrewne"
                ><img src="/ui/icons-black/close-circle.svg"/></a>
            )}
        </li>
    )
}
