import "./AssignmentsBrowser.scss";
import {AppContext} from "../index";
import useClientsDesignAssignmentsBrowserService
    from "../hooks/browserServices/useClientsDesignAssignmentsBrowserService";
import DesignAssignments from "./DesignAssignments";

function ClientsDesignAssignmentsBrowser({range, client}) {
    const appContext = AppContext.getInstance();
    const browserService = useClientsDesignAssignmentsBrowserService(range, appContext, client);

    return (<div className='assignmentsBrowser'>
        <div className="assignmentsBrowserBody" ref={browserService.scrollableBody}>
            <DesignAssignments assignments={browserService.assignments} setAssignments={browserService.setAssignments}></DesignAssignments>
            { browserService?.page?.loading ? <div className='assignmentLoaderIndicator'><img src="/ui/bouncing-circles.svg" alt=""/></div> : '' }
        </div>
    </div>)
}

export default ClientsDesignAssignmentsBrowser;
