import BoxNotifications from "../components/BoxNotifications";
import BoxPlanner from "../components/BoxPlanner";
import BoxDesignAssignments from "../components/BoxDesignAssignments";
import BoxPublishingAssignments from "../components/BoxPublishingAssignments";
import BoxTasks from "../components/BoxTasks";
import React from "react";
import BoxArchives from "../components/BoxArchives";
import BoxOffers from "../components/BoxOffers";

export default class DashboardService {

    static validModules = ['notifications', 'planner', 'designs',  'designs-all', 'assignments', 'tasks', 'archive', 'offers'];
    static defaultConfig = [
        { id: 'notifications', title: "Powiadomienia", checked: true, expanded: false },
        { id: 'planner', title: "Mój Planer", checked: true, expanded: false },
        { id: 'designs', title: "Moje Projekty", checked: true, expanded: false },
        { id: 'designs-all', title: "Projekty", checked: false, expanded: false },
        { id: 'assignments', title: "Moje Zlecenia", checked: true, expanded: false },
        { id: 'tasks', title: "Taski", checked: true, expanded: false },
        { id: 'archive', title: "Archiwum / Teczki", checked: false, expanded: false },
        { id: 'offers', title: "Kosztorysy", checked: false, expanded: false },
    ];

    static config = this.getDefaultConfig();

    static saveConfig(modulesConfig, dashboardLayout) {
        console.log('Saving changes: ', modulesConfig);
        this.config = this.validateConfig(modulesConfig);
        localStorage.setItem('dashboardConfig', JSON.stringify(this.config));
        if (dashboardLayout) localStorage.setItem('dashboardLayout', dashboardLayout);
    }

    static validateConfig(modulesConfig) {
        if (!Array.isArray(modulesConfig)) modulesConfig = [];
        modulesConfig = modulesConfig.filter( row => this.validModules.includes(row.id) );
        modulesConfig = modulesConfig.map( row => {return { id: row.id, title: row.title, checked: !!row.checked, expanded: !!row.expanded }} );
        this.validModules.forEach( moduleName => {
            if (!modulesConfig.find(moduleConfig => moduleConfig.id === moduleName)) {
                modulesConfig.push(this.getDefaultsForModule(moduleName));
            }
        });
        return modulesConfig;
    }

    static loadConfig() {
        const modulesConfigJSON = localStorage.getItem('dashboardConfig');
        try {
            let modulesConfig = modulesConfigJSON ? JSON.parse(modulesConfigJSON) : null;
            this.config = this.validateConfig(modulesConfig);
            return this.config;
        }
        catch (e) {
            console.error('Error parsing dashboard config');
            return this.getDefaultConfig();
        }
    }

    static loadLayout() {
        return localStorage.getItem('dashboardLayout') || '50:50';
    }

    static handleExpandToggle(module, state) {
        console.log(`module ${module.id} toggled, current state: ${state?'TRUE':'FALSE'}`);
        this.saveConfig(this.config.map( cfg => cfg.id === module.id ? {...cfg, expanded: state} : cfg ));
    }

    static getComponents(modulesConfig) {
        if (!modulesConfig) modulesConfig = this.loadConfig();

        const dashboard = [];
        modulesConfig.forEach(module => {
            const attrs = {
                onExpandToggle: (state) => this.handleExpandToggle(module, state),
                expanded: module.expanded ?? false
            }
            if (module.checked) {
                switch (module.id) {
                    case 'notifications': dashboard.push(<BoxNotifications {...attrs} />); break;
                    case 'planner': dashboard.push(<BoxPlanner {...attrs}/>); break;
                    case 'designs': dashboard.push(<BoxDesignAssignments {...{...attrs, essentials: true}} />); break;
                    case 'designs-all': dashboard.push(<BoxDesignAssignments {...{...attrs, essentials: true, all: true}} />); break;
                    case 'assignments': dashboard.push(<BoxPublishingAssignments {...{...attrs, essentials: true}} />); break;
                    case 'tasks': dashboard.push(<BoxTasks {...attrs} />); break;
                    case 'archive': dashboard.push(<BoxArchives {...attrs} />); break;
                    case 'offers': dashboard.push(<BoxOffers {...attrs} />); break;
                    // TODO: Moduł z planerem całego zespołu (np. Aldi)
                    default: break;
                }
            }
        });
        return dashboard
    }

    static getDefaultConfig() {
        return [...this.defaultConfig];
    }

    static getConfig() {
        return this.config;
    }

    static getDefaultsForModule(moduleName) {
        if (!this.validModules.includes(moduleName)) return null;
        return this.defaultConfig.find(module => module.id === moduleName);
    }
}
