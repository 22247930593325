import React, {useEffect, useState} from "react";
import ScreenShade from "./ScreenShade";
import {SharedContext} from "../index";

export default function ModalTarget({children}) {
    const [modal, setModal] = useState([]);

    function setModalComponent(component){
        setModal([
            <ScreenShade key={1} onClick={closeModal}/>,
            component
        ]);
        document.addEventListener('keyup', handleEscape);
    }

    function closeModal() {
        setModal([]);
        document.removeEventListener('keyup', handleEscape);
    }

    function handleEscape(e) {
        if (e.key === "Escape") {
            closeModal();
        };
    }

    return (
        <SharedContext.Provider value={{setModal: setModalComponent, closeModal: closeModal}}>
            {children}
            {[...modal]}
        </SharedContext.Provider>);
}
