import "./AssignmentRow.scss";
import DateTime from "./DateTime";
import AppContext from "../AppContext";

/**
 *
 * @param animate
 * @param animate.id: number
 * @param animate.name: string,
 * @param animate.owner: string,
 * @param animate.createdAt: string,
 * @param animate.previewUrl: string,
 * @param open
 * @return {JSX.Element}
 * @constructor
 */
export default function AnimateRow({animate: animate, open}) {

    return (
        <li className="assignment-row" onClick={ () => open(animate) }>
            <div className="number-and-picture-wrapper">
                <div className="number-wrapper">
                    <div className="number">{ animate.id }</div>
                </div>
            </div>
            <div className="info">
                <div className="name">{ animate.name }</div>
                <div className="owner">wgrane przez { animate.owner }</div>
            </div>
            <div className="dates">
                <div className="date-created"><DateTime label="Utworzone:" date={animate.createdAt}/></div>
            </div>
        </li>
    )
}
