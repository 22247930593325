import useImagePopup from "../hooks/use-image-popup";
import {useEffect} from "react";

export default function PreviewsBrowser({previews}) {
    const [showPopup, closePopup] = useImagePopup()

    useEffect(_ => {
        document.dispatchEvent(new Event('lightbox-init'));
    }, []);

    return <ul className='previews'>
        { previews.map(preview => (
            <li className="picture">
                <a href={preview.image}
                   rel="preview" title={preview.filename} data-id={preview.id} className="cboxElement"
                >
                    <img src={ preview.thumbnail } alt=""
                         onMouseEnter={(e) => showPopup && preview.image && showPopup(preview.image, e) }
                         onMouseLeave={() => closePopup && closePopup()}/>
                </a>
                <a className="download-button" href={preview.url}><i
                    className="fa fa-download"
                ></i></a>
            </li>
          ))
        }
    </ul>
}
