import BoxWrapper from "./BoxWrapper";
import Notifications from "./Notifications";
import "./BoxNotifications.scss";
import useAllNotificationsService from "../hooks/useAllNotificationsService";
export default function BoxNotifications({expanded, onExpandToggle}) {
    return (<BoxWrapper
        title="Powiadomienia"
        icon="bell"
        // cta="Przejdź do Powiadomień"
        // action="/notifications"
        className={"notifications"}
        expanded={expanded}
        collapseOnMobile={true}
        onExpandToggle={onExpandToggle}>
            <Notifications service={useAllNotificationsService()}></Notifications>
        </BoxWrapper>
    )
}
