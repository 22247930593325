import AssignmentsBrowserService from "../../../lib/AssignmentsBrowserService";
import {useEffect, useRef, useState} from "react";

export default function useAssignmentsBrowserService({range, defaultFilters, apiEndpoint, assignmentType}) {
    const browserService = new AssignmentsBrowserService({
        scrollableBody: useRef(null),
        apiEndpoint,
        assignmentType,
        range,
        defaultFilters: defaultFilters
    });

    browserService.initialize({
        assignments: useState(null),
        assignmentsCount: useState(),
        searchQuery: useState(browserService.savedSearchParams.searchQuery),
        searchQueryInput: useState(browserService.savedSearchParams.searchQuery),
        statusFilter: useState(browserService.savedSearchParams.searchFilters),
        company: useState(browserService.savedSearchParams.searchFilterCompany),
        options: useState(browserService.savedSearchParams.searchFilterOptions),
        page: useState({
            loaded: null,
            loading: null
        })
    });

    useEffect( () => browserService.effectCallback(),
        [browserService.page, browserService.searchQuery, browserService.statusFilter, browserService.company]);

    return browserService;
}
