import React, {useContext, useEffect, useRef, useState} from "react";
import AssignmentRow from "./AssignmentRow";
import {SharedContext} from "../index";
import AssignmentStatusPopup from "./AssignmentStatusPopup";
import useAssignmentsEventListeners from "../hooks/useAssignmentsEventListeners";
import useImagePopup from "../hooks/use-image-popup";

function PublishingAssignments({assignments, total, essentials, setAssignments}) {
    let wrapper = useRef(null);
    let ctx = useContext(SharedContext);
    const [showPopup, closePopup] = useImagePopup();

    useAssignmentsEventListeners({assignmentType: 'zlecenia_zlecenie', setAssignments});

    function updateTotal(count) {
        if (wrapper && wrapper.current) wrapper.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
    }

    function togglePublishingAssignmentPin(id) {
        fetch(`/api/assignments/${id}/pin/toggle`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === id);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });
    }

    function openAssignment(id) {
        window.location.href = `/zlecenie/${id}`;
    }

    function changeStatus(assignment) {
        ctx.setModal(<AssignmentStatusPopup assignmentType="publishing" assignment={assignment} onSelect={handleStatusSelect}/> );
    }

    function handleStatusSelect(assignmentId, statusSlug) {
        fetch(`/api/assignments/${assignmentId}/status`, {credentials: "same-origin", method: 'put', body: JSON.stringify({status: statusSlug})})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === assignmentId);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });

        console.log(`Changing status of assignment ${assignmentId} to ${statusSlug}`);
        ctx.closeModal();
    }

    return (
        <ul className="assignments-wrapper" ref={ wrapper }>
            { assignments ? assignments.map((assignment,i) => AssignmentRow({
                assignment,
                togglePin: togglePublishingAssignmentPin,
                open: openAssignment,
                essentials,
                changeStatus,
                href: `/zlecenie/${assignment.id}`,
                showPopup,
                closePopup
            })) : '' }
        </ul>
    );

}

export default PublishingAssignments;
