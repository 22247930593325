import {AppContext} from "../index";

export default class AssignmentsBrowserService {
    searchTimeout;
    assignments;
    setAssignments;
    assignmentsCount;
    setAssignmentsCount;
    savedSearchParams;
    searchQuery;
    setSearchQuery;
    searchQueryInput;
    setSearchQueryInput;
    page;
    setPage;
    statusFilter;
    setStatusFilter;
    company;
    setCompany;
    scrollableBody;
    appContext;
    apiEndpoint;
    assignmentType;
    loading;
    range;
    options;
    setOptions;
    getOptions() { let current; this.setOptions(o => { current = o; return o }); return current; }
    constructor({scrollableBody, apiEndpoint, assignmentType, range, defaultFilters}) {
        this.assignmentType = assignmentType;
        this.apiEndpoint = apiEndpoint;
        this.scrollableBody = scrollableBody;
        this.appContext = AppContext.getInstance();
        this.defaultFilters = defaultFilters;
        this.savedSearchParams = this.loadSearchParams();
        this.range = range;

        this.handleSearchChange = this._handleSearchChange.bind(this);
        this.toggleStatusFilter = this._toggleStatusFilter.bind(this);
        this.handleCompanyChange = this._handleCompanyChange.bind(this);
    }

    /**
     *
     * @param params {{
     *    searchQueryInput: [any, React.Dispatch<React.SetStateAction<any>>],
     *    statusFilter: [any, React.Dispatch<React.SetStateAction<any>>],
     *    assignments: [any, React.Dispatch<React.SetStateAction<any>>],
     *    assignmentsCount: [any, React.Dispatch<React.SetStateAction<any>>],
     *    searchQuery: [any, React.Dispatch<React.SetStateAction<any>>],
     *    company: [any, React.Dispatch<React.SetStateAction<any>>],
     *    page: [{loaded: any, loading: any}, React.Dispatch<React.SetStateAction<{loaded: any, loading: any}>>]
     *    range: 'full'|'personal'
     * }}
     **/
    initialize(params) {
        Object.entries(params).map(([stateId, [state, setState]]) => {
            const upperCaseFirstLetter = str => str[0].toUpperCase() + str.substring(1);
            this[stateId] = state;
            this[`set${upperCaseFirstLetter(stateId)}`] = setState;
        })
    }

    initializeSingle(stateId, [state, setState]) {
        const upperCaseFirstLetter = str => str[0].toUpperCase() + str.substring(1);
        this[stateId] = state;
        this[`set${upperCaseFirstLetter(stateId)}`] = setState;
    }

    effectCallback() {
        if (this.assignments === null && !this.page.loading) {
            this.search(this.searchQuery, 1, this.statusFilter, this.company, this.options);
        }
        if (window.jQuery && navigator.maxTouchPoints < 2) window.jQuery(this.scrollableBody.current).niceScroll({
            cursoropacitymax: .5,
            cursorborder: 'none'
        });
        const loadMoreObserver = this.loadMoreObserver.bind(this);
        this.scrollableBody.current?.addEventListener('scroll', loadMoreObserver);
        return () => {
            this.scrollableBody.current?.removeEventListener('scroll', loadMoreObserver);
        }
    }
    search(value, pageNumber, searchStatusFilter, company, options) {
        searchStatusFilter = searchStatusFilter === undefined ? this.statusFilter : searchStatusFilter;
        company = company === undefined ? this.company : company;
        pageNumber = pageNumber || 1;
        this.setPage({
            loading: pageNumber,
            loaded: null
        });
        this.setSearchQuery(value);
        let parsedOptions = null;
        if (options && typeof options === 'object') {
            parsedOptions = {};
            for (const [key, value] of Object.entries(options)) {
                parsedOptions[key] = typeof value === 'object' && value.hasOwnProperty('value') ? value.value : value;
            }
        }

        fetch(this.apiEndpoint, {
            credentials: "same-origin",
            method: 'post',
            body: JSON.stringify({
                query: value,
                page: pageNumber,
                filter: Object.entries(searchStatusFilter).filter(([key, val]) => val).map(([key, val]) => key),
                company: company && company.value ? company.value : null,
                range: this.range,
                options: parsedOptions
            })
        })
        .then(resp => resp.json())
        .then(json => {
            const keyName = ['design', 'publishing'].includes(this.assignmentType) ? 'assignments' : `${this.assignmentType}s`;
            this.setAssignments(current => pageNumber === 1 ? json[keyName] : current.concat(json[keyName]));
            this.setAssignmentsCount(json.total);
            // updateTotal(json.total)
            this.setPage({
                loaded: pageNumber,
                loading: null
            });
            this.loading = false;
            setTimeout(() => {
                if (window.jQuery) window.jQuery(this.scrollableBody.current).getNiceScroll().resize();
            }, 100);
        });
    }

    loadMoreObserver(e) {
        const frame = e.target;
        const threshold = 50; // 50 px before scroll end
        if (frame.scrollTop > frame.offsetHeight) {
            const scrollBottom = frame.scrollTop + frame.offsetHeight;
            if (scrollBottom + threshold > frame.scrollHeight) {
                if (!this.loading && !this.page.loading && this.assignments.length < this.assignmentsCount) {
                    this.loading = true;
                    this.search(this.searchQuery, this.page.loaded + 1, this.statusFilter, this.company, this.options);
                }
            }
        }
    }

    handleOptionChange(option, value) {
        let updatedOptions = this.options || {};
        updatedOptions = {...(this.options || {}), [option]: value}
        this.setOptions(updatedOptions);
        this.saveSearchParams(this.searchQuery, this.statusFilter, this.company, updatedOptions);
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.search(this.currentSearchQuery, 1, this.statusFilter, this.company, updatedOptions);
    }

    _handleSearchChange(value) {
        // this.setSearchQueryInput(value);
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.saveSearchParams(value, this.statusFilter, this.company, this.options);
            this.search(value, 1, this.statusFilter, this.company, this.options);
            this.currentSearchQuery = value;
        }, 700);
    }

    _toggleStatusFilter(slug, newStatus) {
        const newStatusFilter = {...this.statusFilter, [slug]: newStatus};
        this.setStatusFilter(newStatusFilter);
        this.saveSearchParams(this.searchQuery, newStatusFilter, this.company, this.options);
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.search(this.currentSearchQuery, 1, newStatusFilter, this.company, this.options);
    }
    _handleCompanyChange(company) {
        this.setCompany(company);
        this.saveSearchParams(this.searchQuery, this.statusFilter, company, this.options);
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.search(this.currentSearchQuery, 1, this.statusFilter, company, this.options);

        // TODO: Zdaje się tu być inny "Closure" - być może ma to związek z podpięciem tej metody pod standardowy EventListener a nie przez Reacta !!!
        // TODO: Nie działa z "Select2"
    }

    // get handleSearchChange() {
    //     return this._handleSearchChange.bind(this);
    // }
    //
    // get toggleStatusFilter() {
    //     return this._toggleStatusFilter.bind(this);
    // }
    //
    // get handleCompanyChange() {
    //     return this._handleCompanyChange.bind(this);
    // }


    saveSearchParams(searchQuery, searchFilters, company, options) {
        window.localStorage.setItem(this.assignmentType+'AssignmentBrowserSearchQuery', searchQuery);
        window.localStorage.setItem(this.assignmentType+'AssignmentBrowserFilters', JSON.stringify(searchFilters));
        window.localStorage.setItem(this.assignmentType+'AssignmentBrowserFilterCompany', JSON.stringify(company));
        window.localStorage.setItem(this.assignmentType+'AssignmentBrowserFilterOptions', JSON.stringify(options));
    }

    loadSearchParams() {
        let filters;
        let company;
        let options;
        const serializedFilters = window.localStorage.getItem(this.assignmentType+'AssignmentBrowserFilters');
        if (serializedFilters) {
            try {
                filters = JSON.parse(serializedFilters);
            } catch (e) {
                filters = null;
            }
        }
        const serializedFilterCompany = window.localStorage.getItem(this.assignmentType+'AssignmentBrowserFilterCompany');
        if (serializedFilterCompany) {
            try {
                company = JSON.parse(serializedFilterCompany);
            } catch (e) {
                company = null;
            }
        }
        const serializedFilterOptions = window.localStorage.getItem(this.assignmentType+'AssignmentBrowserFilterOptions');
        if (serializedFilterOptions) {
            try {
                options = JSON.parse(serializedFilterOptions);
            } catch (e) {
                options = null;
            }
        }
        this.currentSearchQuery = window.localStorage.getItem(this.assignmentType+'AssignmentBrowserSearchQuery') || ''
        return {
            searchQuery: this.currentSearchQuery,
            searchFilters: filters || this.defaultFilters,
            searchFilterCompany: company || '',
            searchFilterOptions: options
        }
    }
}
