import React, {useEffect, useRef, useState} from "react";
import TaskRow from "./TaskRow";
import "./Tasks.scss";

export default function Tasks({onlyMyOwn}) {

    //TODO: inreactivity

    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState({target: 'new'});
    const [tasksCount, setTasksCount] = useState(null);

    const wrapperElementRef = useRef(null);
    const newTaskElementRef = useRef(null);

    useEffect(() => {
        fetch('/api/tasks', {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setTasks(json.tasks);
                setTasksCount(json.total);
                updateTotal(json.total);
            });
    }, []);

    function updateTotal(count) {
        if (wrapperElementRef.current) wrapperElementRef.current.dispatchEvent(new CustomEvent('setBadge', {
            detail: count,
            bubbles: true
        }));
        document.dispatchEvent(new CustomEvent('remainigTasksUpdate', {detail: {count}, bubbles: true}));
    }

    function toggleTask(id, done) {
        setTasks(tasks => tasks.map(task => task.id === id ? {...task, saving: true} : task));
        fetch(`/api/tasks/${id}`, {
            method: "put",
            credentials: "same-origin",
            body: JSON.stringify({done: done})
        })
            .then(resp => resp.json())
            .then(json => {
                setTasks(json.tasks);
                setTasksCount(json.total);
                updateTotal(json.total)
            });
    }

    async function saveTask(id, newContent) {
        setTasks(tasks => tasks.map(task => task.id === id ? {...task, saving: true} : task));
        const resp = await fetch(`/api/tasks/${id}`, {
            method: "put",
            credentials: "same-origin",
            body: JSON.stringify({description: newContent})
        });
        await afterTaskSave(resp);
        window.getSelection().removeAllRanges();
    }

    async function createTask(id, newContent) {
        setNewTask({...newTask, saving: true, description: newContent});
        const userProfileId = document.querySelector('#loggedInUser').dataset.profilId;
        const resp = await fetch(`/api/tasks`, {
            method: "post",
            credentials: "same-origin",
            body: JSON.stringify({
                description: newContent,
                assigneeId: onlyMyOwn ? userProfileId : null
            })
        });
        await afterTaskSave(resp);
        setNewTask({target: 'new', description: ''});
        setTimeout(() => {
            // Wait for IU refresh and scroll to new task input
            newTaskElementRef.current?.scrollIntoView({behavior: "smooth"});
        });
    }

    async function afterTaskSave(resp) {
        const json = await resp.json();
        setTasks(json.tasks);
        setTasksCount(json.total);
        updateTotal(json.total);
    }

    return (
        <ul className="tasks-wrapper" ref={wrapperElementRef}>
            {tasks.map((task, i) => <TaskRow
                    task={Object.assign(task, {target: 'edit'})}
                    toggleTask={toggleTask}
                    saveTask={saveTask}
                    withRefs={onlyMyOwn}
                ></TaskRow>
            )}
            <TaskRow
                task={newTask}
                toggleTask={toggleTask}
                saveTask={createTask}
                withRefs={onlyMyOwn}
                ref={newTaskElementRef}
            ></TaskRow>
        </ul>
    )
}
