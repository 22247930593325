import React, {useContext, useEffect, useState} from "react";
import {SharedContext} from "../index";
import AssignmentStatusPopup from "./AssignmentStatusPopup";
import ArchiveRow from "./ArchiveRow";

export default function Archives({archives, total, quickAccess}) {

    let wrapper = null;
    let ctx = useContext(SharedContext);

    function updateTotal(count) {
        if (wrapper) wrapper.dispatchEvent(new CustomEvent('setBadge', {detail: count, bubbles: true}));
    }

    function openArchive(id) {
        window.location.href = `/przetargi/show/id/${id}`;
    }

    return (
        <ul className="assignments-wrapper" ref={elem => wrapper = elem}>
            { archives ? archives.map((archive, i) => ArchiveRow({
                archive,
                open: openArchive,
                quickAccess
            })) : '' }
        </ul>
    )
}
