import './Dashboard.scss';
import React, {useContext, useEffect, useState} from "react";
import DashboardEdit from "./components/DashboardEdit";
import DashboardService from "./lib/DashboardService";
import {SharedContext} from "./index";

function Dashboard(node, editButton) {
  const [moduleComponents, setModuleComponents] = useState(DashboardService.getComponents());
  let [layout, setLayout] = useState(DashboardService.loadLayout());

  const {setModal, closeModal} = useContext(SharedContext);

  function changeDashboard(modulesConfig, dashboardLayout) {
    DashboardService.saveConfig(modulesConfig, dashboardLayout);
    setupDashboard(modulesConfig, dashboardLayout);
  }

  function setupDashboard(modulesConfig, dashboardLayout) {
    const components = DashboardService.getComponents(modulesConfig);
    setModuleComponents(components);
    setLayout(dashboardLayout ?? '50:50');
  }

  function dashboardEditButtonHandle() {
    const modulesConfig = DashboardService.getConfig();
    setModal(<DashboardEdit config={modulesConfig} layout={layout} onChanges={changeDashboard} close={closeModal}/>);
  }

  useEffect( () => {
    node.editButton && node.editButton.addEventListener('click', dashboardEditButtonHandle);
    node.node.dataset.elements = moduleComponents.length;
    node.node.dataset.layout = layout;

    return () => {
      node.editButton && node.editButton.removeEventListener('click', dashboardEditButtonHandle);
    }
  }, [moduleComponents, layout]);


  return (
    <>
      {[...moduleComponents]}
    </>
  )
}

export default Dashboard;
