import useAssignmentsBrowserService from "./generic/useAssignmentsBrowserService";

export default function useOffersBrowserService(range, appContext) {
    return useAssignmentsBrowserService({
        range,
        defaultFilters: appContext.value.offerStatusesFilter,
        apiEndpoint: '/api/offers/search',
        assignmentType: 'offer'
    });
}
