import "./AssignmentRow.scss";
import "./ArchiveRow.scss";
import DateTime from "./DateTime";
import AppContext from "../AppContext";

/**
 *
 * @param archive
 * @param archive.id: number
 * @param archive.name: string,
 * @param archive.client: string,
 * @param archive.owner: string,
 * @param archive.createdAt: string,
 * @param archive.updatedAt: string,
 * @param archive.briefingAt: string
 * @param archive.presentationAt: string}
 * @param open
 * @return {JSX.Element}
 * @constructor
 */
export default function ArchiveRow({archive, open, quickAccess}) {
    const appContext = AppContext.getInstance();

    return (
        <li className="assignment-row archive-row" onClick={ () => open(archive.id) }>
            <div className="number-and-picture-wrapper">
                <div className="number-wrapper">
                    <div className="number">{ archive.id }</div>
                </div>
                <div className="tags">
                    { archive.tags ? Object.values(archive.tags).map(tag => {
                        const tagColor = appContext.value.archiveTags.filter(tagDefinition => tagDefinition.name === tag).map(tagDefinition => tagDefinition.color).shift() || '#f0f0f0';
                        return <div className="tag" style={{backgroundColor: tagColor}}>{tag}</div>
                    }) : '' }
                </div>
            </div>
            <div className="info">
                <div className="name">{ archive.name }</div>
                <div className="owner">{ archive.client } / prowadzone przez { archive.owner }</div>
            </div>
            <div className="dates">
                {/*<div className="date-created"><DateTime label="Utworzone:" date={archive.createdAt}/></div>*/}
                {/*<div className="date-updated"><DateTime label="Zmodyfikowane:" date={archive.updatedAt}/></div>*/}
                <div className="date-briefing"><DateTime label="Data briefu: " date={archive.briefingAt} /></div>
                <div className="date-presentaion"><DateTime label="Data prezentacji: " date={archive.presentationAt} /></div>
            </div>
            { quickAccess && archive.attachments ? (
                <div className="attachments">
                    {archive.attachments.filter(attachment => attachment.type === quickAccess).map(attachment => (
                        <a class="quickAccessLink" href={`/przetargi/GetFile/typ/zalacznik/id/${attachment.id}`}>{attachment.fileName}</a>
                    ))}
                </div>
            ) : ''}
        </li>
    )
}
