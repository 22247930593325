import "./AssignmentsBrowser.scss";
import DesignAssignments from "./DesignAssignments";
import AssignmentsBrowserHeader from "./AssignmentsBrowserHeader";
import {AppContext} from "../index";
import useDesignAssignmentsBrowserService from "../hooks/browserServices/useDesignAssignmentsBrowserService";

function DesignAssignmentsBrowser({range}) {
    const appContext = AppContext.getInstance();
    const browserService = useDesignAssignmentsBrowserService(range, appContext);

    return (<div className='assignmentsBrowser'>
        <AssignmentsBrowserHeader
            searchQueryInput={browserService.searchQueryInput}
            handleSearchChange={val => browserService.handleSearchChange(val)}
            statusFilter={browserService.statusFilter}
            toggleStatusFilter={(slug,status) => browserService.toggleStatusFilter(slug,status)}
            company={browserService.company}
            toggleCompany={(company => browserService.handleCompanyChange(company))}
            statuses = {appContext.value.statuses}
        />
        <div className="assignmentsBrowserBody" ref={browserService.scrollableBody}>
            <DesignAssignments assignments={browserService.assignments} setAssignments={browserService.setAssignments}></DesignAssignments>
            { browserService?.page?.loading ? <div className='assignmentLoaderIndicator'><img src="/ui/bouncing-circles.svg" alt=""/></div> : '' }
        </div>
    </div>)
}

export default DesignAssignmentsBrowser;
