import useAssignmentsBrowserService from "./generic/useAssignmentsBrowserService";

export default function useArchiveBrowserService(range, appContext) {
    return useAssignmentsBrowserService({
        range,
        defaultFilters: appContext.value.archiveTagsFilter,
        apiEndpoint: '/api/archives/search',
        assignmentType: 'archive'
    });
}
