import BoxWrapper from "./BoxWrapper";
import Tasks from "./Tasks";

export default function BoxTasks({expanded, onExpandToggle}) {
    return (<BoxWrapper
        title="Moje Zadania"
        icon="tasks"
        // cta="Przejdź do Zadań"
        // action="/tasks"
        className={"tasks"+(expanded ? " expanded":'')}
        collapseOnMobile={true}
        onExpandToggle={onExpandToggle}>
            <div className="tasks_list">
                <Tasks onlyMyOwn={true}></Tasks>
            </div>
        </BoxWrapper>
    )
}
