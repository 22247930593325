import "./AssignmentsBrowser.scss";
import Offers from "./Offers";
import {useEffect, useRef, useState} from "react";
import AssignmentsBrowserHeader from "./AssignmentsBrowserHeader";
import AssignmentsBrowserService from "../lib/AssignmentsBrowserService";
import {AppContext} from "../index";
import ArchiveBrowserHeader from "./ArchiveBrowserHeader";
import Archives from "./Archives";
import useArchiveBrowserService from "../hooks/browserServices/useArchiveBrowserService";

function ArchiveBrowser({range}) {
    const appContext = AppContext.getInstance();
    const browserService = useArchiveBrowserService(range, appContext);
    const [type, setType] = useState(browserService.savedSearchParams.searchFilterOptions?.archiveType || '');
    const [quickAccess, setQuickAccess] = useState('');

    function handleTypeChange(type) {
        setType(type);
        browserService.handleOptionChange('archiveType', type);
    }

    function handleQuickAccessChange(quickAccess) {
        setQuickAccess(quickAccess);
    }

    return (<div className='assignmentsBrowser'>
        <ArchiveBrowserHeader
            searchQueryInput={browserService.searchQueryInput}
            handleSearchChange={val => browserService.handleSearchChange(val)}
            statusFilter={browserService.statusFilter}
            toggleStatusFilter={(slug,status) => browserService.toggleStatusFilter(slug,status)}
            company={browserService.company}
            toggleCompany={(company => browserService.handleCompanyChange(company))}
            type={ type }
            toggleType={ type => { handleTypeChange(type) } }
            statuses={appContext.value.archiveTags}
            quickAccess={ quickAccess }
            toggleQuickAccess={ quickAccess => { handleQuickAccessChange(quickAccess) } }
        />
        <div className="assignmentsBrowserBody" ref={browserService.scrollableBody}>
            <Archives archives={browserService.assignments} quickAccess={quickAccess ? quickAccess.value : null}></Archives>
            { browserService?.page?.loading ? <div className='assignmentLoaderIndicator'><img src="/ui/bouncing-circles.svg" alt=""/></div> : '' }
        </div>
    </div>)
}

export default ArchiveBrowser;
