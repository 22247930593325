import "./AssignmentRow.scss";
import DateTime from "./DateTime";
import AppContext from "../AppContext";

/**
 *
 * @param offer
 * @param offer.id: number
 * @param offer.name: string,
 * @param offer.client: string,
 * @param offer.owner: string,
 * @param offer.status: string,
 * @param offer.createdAt: string,
 * @param offer.updatedAt: string,
 * @param open
 * @return {JSX.Element}
 * @constructor
 */
export default function OfferRow({offer, open, changeStatus}) {
    const appContext = AppContext.getInstance();

    return (
        <li className="assignment-row" onClick={ () => open(offer.id) }>
            <div className="number-and-picture-wrapper">
                <div className="number-wrapper" onClick={(e) => {e.stopPropagation(); changeStatus(offer)}}>
                    <div className="status number" data-status={offer.status}>{ offer.id }</div>
                </div>
            </div>
            <div className="info">
                <div className="name">{ offer.name }</div>
                <div className="owner">{ offer.client } / prowadzone przez { offer.owner }</div>
            </div>
            <div className="dates">
                <div className="date-offer"><DateTime label="Data:" date={offer.date}/></div>
                <div className="date-valid"><DateTime label="Ważny do:" date={offer.validUntil}/></div>
                {/*<div className="date-created"><DateTime label="Utworzone:" date={offer.createdAt}/></div>*/}
                <div className="date-updated"><DateTime label="Zmodyfikowane:" date={offer.updatedAt}/></div>
                {/*<div className="date-briefing"><DateTime label="Data briefu: " date={offer.briefingAt} /></div>*/}
                {/*<div className="date-presentaion"><DateTime label="Data prezentacji: " date={offer.presentationAt} /></div>*/}
            </div>
        </li>
    )
}
