import useAssignmentsBrowserService from "./generic/useAssignmentsBrowserService";

export default function useClientsDesignAssignmentsBrowserService(range, appContext, client) {
    return useAssignmentsBrowserService({
        range,
        defaultFilters: appContext.value.statusFilter,
        apiEndpoint: `/api/clients/${client}/design-assignments`,
        assignmentType: 'design'
    });
}
