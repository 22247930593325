import BoxWrapper from "./BoxWrapper";
import PublishingAssignments from "./PublishingAssignments";
import {useEffect, useRef, useState} from "react";
import {Tooltip} from "react-tooltip";

export default function BoxPublishingAssignments({expanded, onExpandToggle, essentials}) {

    const [assignments, setAssignments] = useState([]);
    const [assignmentsCount, setAssignmentsCount] = useState(null);
    const boxWrapper = useRef(null);

    useEffect( () => {
        fetch('/api/assignments/my/current', {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setAssignments(json.assignments);
                setAssignmentsCount(json.total);
                updateTotal(json.total)
            });
    }, []);

    function updateTotal(count) {
        if (boxWrapper && boxWrapper.current) boxWrapper.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
    }

    return (<BoxWrapper
        title="Moje Zlecenia"
        icon="assignment"
        cta="Przejdź do Moich Zleceń"
        action="/zlecenia/moje"
        className={"fav-zlecenia"+(expanded ? " expanded":'')}
        collapseOnMobile={true}
        ref={boxWrapper}
        onExpandToggle={onExpandToggle}
        loaderCondition={ () => assignmentsCount === null}>
            <div className="publishing_assignments_list assignments_list">
                <PublishingAssignments assignments={assignments} total={assignmentsCount} essentials={essentials} setAssignments={setAssignments}></PublishingAssignments>
            </div>
            <Tooltip id="common-tooltip" />
        </BoxWrapper>
    )
}
