function DateTime({label, date, pastClass, withTime}) {
    const d = new Date(date);
    const day = d.getDate();
    const month = `0${d.getMonth() + 1}`.substr(-2);
    const year = d.getFullYear();

    const hours = d.getHours();
    const minutes = `0${d.getMinutes()}`.substr(-2);

    if (label) label = `${label} `;

    let dateString = `${label}${day}.${month}.${year}`;
    if (withTime) {
        dateString += ` / ${hours}:${minutes}`
    }

    const now = new Date();
    const obsolete = now.valueOf() > d.valueOf();

    if (obsolete && pastClass) {
        return <span className={pastClass}><i className="fa fa-exclamation-circle"></i> {dateString}</span>
    }

    return dateString;
}

export default DateTime;
