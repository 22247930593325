import {useEffect} from "react";

export default function useAssignmentEventListeners({assignmentType, assignmentId, setNotifications}) {
    function onClearAllNotifications(e) {
        if (e.detail?.count === 0) {
            setNotifications([]);
        }
    }

    function onNotificationsClosed(e) {
        const ids = e.detail?.ids?.map(id => parseInt(id, 10));
        if (Array.isArray(ids)) {
            setNotifications( notifications => notifications.filter(n => !ids.includes(parseInt(n.id)) ))
        }
    }

    function onNotificationsUpdate(e) {
        setNotifications(notifications => {
            if (!notifications) return notifications;

            e.detail?.notifications
                .filter(notification => notification.assignmentType === assignmentType)
                .filter(notification => notification.assignmentId === assignmentId)
                .forEach( newNotification => {
                    if (!notifications.find(existingNotification => parseInt(existingNotification.id) === parseInt(newNotification.id))) {
                        notifications = [...notifications, {
                            id: parseInt(newNotification.id),
                            text: newNotification.text
                        }];
                    }
                });
            return notifications;
        })
    }

    useEffect( () => {
        document.addEventListener('notificationsUpdate', onClearAllNotifications);
        document.addEventListener('closedNotifications', onNotificationsClosed);
        document.addEventListener('notificationsUpdate', onNotificationsUpdate);
        return () => {
            document.removeEventListener('notificationsUpdate', onClearAllNotifications);
            document.removeEventListener('closedNotifications', onNotificationsClosed);
            document.removeEventListener('notificationsUpdate', onNotificationsUpdate);
        }
    }, []);
}
