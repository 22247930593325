import classNames from "../lib/classNames";
import {forwardRef} from "react";

const TaskRow = forwardRef(({task, toggleTask, saveTask, withRefs}, ref) => {
    let descriptionHtml = {__html: task.description}
    let savedValue = task.description;
    function saveCurrentValue(e) {
        if (e.type === 'focus') {
            savedValue = e.target.innerHTML;
        }
    }

    function watchAccept(e, task) {
        if ((e.nativeEvent instanceof KeyboardEvent && (e.key === 'Enter' || e.key === 'Escape')) || (e.nativeEvent instanceof FocusEvent && e.type === 'blur')) {
            const currentValue = e.target.innerHTML;
            if (e.key === 'Escape') {
                e.target.blur();
                window.getSelection().removeAllRanges();
            }
            e.preventDefault();
            e.stopPropagation();
            if (savedValue !== currentValue) {
                saveTask(task.id, currentValue);
                console.log('TASK ACCEPTED: '+currentValue);
            }
        }
    }

    return (

        <li data-id={task.id} data-target={"/tasks/" + (task.target ?? 'new')} className={classNames({new: task.target === 'new', assigned: task.assignee})} ref={ref}>
            { task.saving ?
                <i className="fa fa-2x fa-spinner fa-spin"></i>
                :
                <div className="task_status" onClick={() => toggleTask(task.id, !task.done)}>
                { task.done
                    ? <i class="fa fa-check on" title={task.doneBy +' @ '+ task.doneAt}></i>
                    : <i class="fa off"></i>
                }
                </div>
            }

            <div className="task_description"
                 contentEditable="true"
                 dangerouslySetInnerHTML={descriptionHtml}
                 onKeyDown={ e => watchAccept(e, task) }
                 onBlur={ e => watchAccept(e, task) }
                 onFocus={saveCurrentValue}
            >
            </div>

            { (withRefs && task.relZlecenieId) ?
                        <a className="reference_id"
                           href={"/zlecenie/" + task.relZlecenieId}
                           title={task.relZlecenie.temat}
                        >
                            {task.relZlecenieId}
                        </a> : <></>
            }

            { (withRefs && task.relDesignId) ?
                <a className="reference_id"
                   href={`/design/${task.relDesignId}/show`}
                   title={task.relDesign.temat}
                >
                    {task.relDesignId}
                </a> : <></>
            }

            { (!withRefs && task.assigneeId) ?
                <div className="task_assignee" title={task.addedBy + ' -> ' + task.assignee }>
                    <img src={task.assigneeAvatarUrl} alt="" />
                </div> : <></>
            }

            { (!withRefs && !task.assigneeId) ?
                <div className="task_assignee empty" title="Przydziel zadanie">
                    <i className="fa fa fa-user unassigned"></i>
                </div> : <></>
            }
        </li>
    )
});

export default TaskRow;
