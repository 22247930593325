import {useEffect, useRef} from "react";

export default function useSearchInput(searchQueryInput, handleSearchChange) {
    const inputRef = useRef(searchQueryInput);

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = searchQueryInput;
    }, [])

    useEffect( () => {
        document.addEventListener('keyup', clearSearchOnEscape);
        return () => {
            document.removeEventListener('keyup', clearSearchOnEscape);
        }
    }, []);

    function clearSearchOnEscape(e) {
        if (e.key === "Escape") {
            clearInput();
        }
    }

    function clearInput() {
        inputRef.current.value = '';
        handleSearchChange('');
        inputRef.current.focus();
    }

    return [inputRef, clearInput]
}
