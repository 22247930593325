import React, {useContext, useEffect, useRef, useState} from "react";
import AssignmentRow from "./AssignmentRow";
import {SharedContext} from "../index";
import AssignmentStatusPopup from "./AssignmentStatusPopup";
import useAssignmentsEventListeners from "../hooks/useAssignmentsEventListeners";
import useImagePopup from "../hooks/use-image-popup";

export default function DesignAssignments({assignments, total, essentials, setAssignments}) {

    let wrapper = useRef(null);
    let ctx = useContext(SharedContext);
    const [showPopup, closePopup] = useImagePopup();

    useAssignmentsEventListeners({assignmentType: 'design_zlecenie', setAssignments});

    function updateTotal(count) {
        if (wrapper && wrapper.current) wrapper.current.dispatchEvent(new CustomEvent('setBadge', {detail: count, bubbles: true}));
    }

    function toggleDesignAssignmentPin(id) {
        fetch(`/api/designs/${id}/pin/toggle`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === id);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });
    }

    function changeStatus(assignment) {
        ctx.setModal(<AssignmentStatusPopup assignmentType="design" assignment={assignment} onSelect={handleStatusSelect}/> );
    }

    function handleStatusSelect(assignmentId, statusSlug) {
        fetch(`/api/design/${assignmentId}/status`, {credentials: "same-origin", method: 'put', body: JSON.stringify({status: statusSlug})})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === assignmentId);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });

        console.log(`Changing status of assignment ${assignmentId} to ${statusSlug}`);
        ctx.closeModal();
    }

    function openDesign(id) {
        window.location.href = `/design/${id}/show`;
    }

    return (
        <ul className="assignments-wrapper" ref={wrapper}>
            { assignments ? assignments.map((assignment, i) => AssignmentRow({
                assignment,
                togglePin: toggleDesignAssignmentPin,
                open: openDesign,
                essentials,
                changeStatus,
                href: `/design/${assignment.id}/show`,
                showPopup,
                closePopup
            })) : '' }
        </ul>
    )
}
