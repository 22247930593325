import "./AssignmentsBrowser.scss";
import Offers from "./Offers";
import {useEffect, useRef, useState} from "react";
import AssignmentsBrowserHeader from "./AssignmentsBrowserHeader";
import AssignmentsBrowserService from "../lib/AssignmentsBrowserService";
import {AppContext} from "../index";
import useOffersBrowserService from "../hooks/browserServices/useOffersBrowserService";

function OffersBrowser({range}) {
    const appContext = AppContext.getInstance();

    const browserService = useOffersBrowserService(range, appContext);

    return (<div className='assignmentsBrowser'>
        <AssignmentsBrowserHeader
            searchQueryInput={browserService.searchQueryInput}
            handleSearchChange={val => browserService.handleSearchChange(val)}
            statusFilter={browserService.statusFilter}
            toggleStatusFilter={(slug,status) => browserService.toggleStatusFilter(slug,status)}
            company={browserService.company}
            toggleCompany={(company => browserService.handleCompanyChange(company))}
            statuses={appContext.value.offerStatuses}
        />
        <div className="assignmentsBrowserBody" ref={browserService.scrollableBody}>
            <Offers offers={browserService.assignments} setOffers={browserService.setAssignments}></Offers>
            { browserService?.page?.loading ? <div className='assignmentLoaderIndicator'><img src="/ui/bouncing-circles.svg" alt=""/></div> : '' }
        </div>
    </div>)
}

export default OffersBrowser;
