import Notifications from "./Notifications";
import {useEffect, useRef, useState} from "react";
import "./NotificationsModal.scss";
import useAllNotificationsService from "../hooks/useAllNotificationsService";

export default function NotificationsModal() {
    let [visible, setVisible] = useState(false);
    const wrapper = useRef();

    useEffect(addDocumentEventListeners, []);
    useEffect(toggleGlobalNotificationsModalStatus, [visible]);
    useEffect(toggleEscKeyListener, [visible]);

    return <div className='box-wrapper' ref={wrapper}><div className="content"><Notifications service={useAllNotificationsService()}/></div></div>;

    function pwaBadgeUpdate(e) {
        if ('setAppBadge' in navigator) {
            navigator.setAppBadge(e.detail?.count).catch((error) => {
                console.error("Couldn't set app bagde");
            });
        }
    }

    function toggleGlobalNotificationsModalStatus() {
        document.querySelector('body').dataset.notificationsModalVisible = visible?'true':'false';
        // document.getElementById('content').classList.toggle('blurred', visible);
    }

    function addDocumentEventListeners() {
        document.addEventListener('showNotifications', showNotifications);
        document.addEventListener('hideNotifications', hideNotifications);
        document.addEventListener('toggleNotificationsModal', toggleNotificationsModal);
        document.addEventListener('notificationsUpdate', pwaBadgeUpdate);
        wrapper.current.addEventListener('notificationsUpdate', hideIfEmpty);

        return () => {
            document.removeEventListener('showNotifications', showNotifications);
            document.removeEventListener('hideNotifications', hideNotifications);
            document.removeEventListener('toggleNotificationsModal', toggleNotificationsModal);
            document.removeEventListener('notificationsUpdate', pwaBadgeUpdate);
            wrapper.current.removeEventListener('notificationsUpdate', hideIfEmpty);
        }
    }

    function toggleEscKeyListener() {
        if (visible) { document.addEventListener('keyup', escapeKeyListener); }
        else { document.removeEventListener('keyup', escapeKeyListener); }
        return () => {
            if (visible) document.removeEventListener('keyup', escapeKeyListener);
        }
    }

    function escapeKeyListener(e) {
        if (e.key === 'Escape') {
            const event = new Event('hideNotifications');
            document.dispatchEvent(event);
        }
    }

    function showNotifications() {
        setVisible(true);
    }

    function hideNotifications() {
        setVisible(false);
    }

    function hideIfEmpty(e) {
        if (e.detail?.count === 0) { hideNotifications() }
    }

    function toggleNotificationsModal() {
        setVisible(visible => !visible);
    }
}
