import BoxWrapper from "./BoxWrapper";
import {useEffect, useRef, useState} from "react";
import Archives from "./Archives";

export default function BoxArchives({expanded, onExpandToggle, essentials}) {

    const [archives, setArchives] = useState([]);
    const [archivesCount, setArchivesCount] = useState(null);
    const boxWrapper = useRef(null);

    useEffect( () => {
        fetch('/api/archives', {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setArchives(json.archives);
                setArchivesCount(json.total);
                updateTotal(json.total)
            });
    }, []);

    function updateTotal(count) {
        if (boxWrapper && boxWrapper.current) boxWrapper.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
    }

    return (<BoxWrapper
        title="Archiwum/Teczki"
        icon="box"
        cta="Przejdź do Przetargów"
        action="/przetargi"
        className={"przetargi"+(expanded ? " expanded":'')}
        collapseOnMobile={true}
        ref={boxWrapper}
        onExpandToggle={onExpandToggle}
        loaderCondition={ () => archivesCount === null}>
            <div className="archives_list assignments_list">
                <Archives archives={archives} total={archivesCount}></Archives>
            </div>
        </BoxWrapper>
    )
}
