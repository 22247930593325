import React, {useContext} from "react";
import {SharedContext} from "../index";
import OfferRow from "./OfferRow";
import OfferStatusPopup from "./OferStatusPopup";

export default function Offers({offers, total, setOffers}) {

    let wrapper = null;
    let ctx = useContext(SharedContext);

    function updateTotal(count) {
        if (wrapper) wrapper.dispatchEvent(new CustomEvent('setBadge', {detail: count, bubbles: true}));
    }

    function openOffer(id) {
        window.location.href = `/kosztorysy/view/id/${id}`;
    }

    function changeStatus(offer) {
        ctx.setModal(<OfferStatusPopup offer={offer} onSelect={handleStatusSelect}/> );
    }

    function handleStatusSelect(offerId, statusSlug) {
        fetch(`/api/offer/${offerId}/status`, {credentials: "same-origin", method: 'put', body: JSON.stringify({status: statusSlug})})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const offerIndex = offers.findIndex(a => a.id === offerId);
                    const newOffers = [...offers];
                    newOffers.splice(offerIndex, 1, json.offer);
                    setOffers(newOffers);
                }
            });

        console.log(`Changing status of offer ${offerId} to ${statusSlug}`);
        ctx.closeModal();
    }

    return (
        <ul className="assignments-wrapper" ref={elem => wrapper = elem}>
            { offers ? offers.map((offer, i) => OfferRow({
                offer,
                open: openOffer,
                changeStatus,
            })) : '' }
        </ul>
    )
}
